<template>
  <div>
    <h3 ref="ia">
      Waves
    </h3>
    <p>
      We are all familiar with waves. Sea waves, ripples of wave when
      a stone is thrown into water etc. are some of the everyday examples
      of waves that we see everyday. Now we will look at the mathematical description
      of waves.
    </p>
    <h3 ref="ja">
      Governing Equation
    </h3>
    <p>
      Mathematically, a function \(y=f(x,t)\) is called a wave function if it
      satisfies the following differential equation:
      $$\frac{\partial^2 y}{\partial x^2}=\frac{1}{c^2}\frac{\partial^2y}{\partial t^2}$$
      As you would've realized there are many functions which satisfy
      the above differential equation. The simplest of these is the sinusoidal wave function.
      The general form of the sinusoidal wave function is:
      $$y=A \sin(\omega t+kx+\phi)$$
      Here, \(\omega\) is called the "angular frequency" and \(k\) is called the "wave number".
    </p>

    <h3>Amplitude \((A)\)</h3>
    <p>Amplitude is the maximum value of the wave function. It is usually represented by \(A\).</p>
    <h3>Frequency \((\nu)\)</h3>
    <p>
      The number of oscillations per second is called the frequency of a wave.
      The frequency is related to the angular frequency as follows:
      $$\omega=2\pi \nu$$
    </p>
    <h3> Wavlength \((\lambda)\)</h3>
    <p>
      The distance between two consecutive troughs or crests is called the wavelength.
      It is related to the wave number as follows:
      $$k=\frac{2\pi}{\lambda}$$
    </p>
    <p>
      The following diagram from <a href="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Sine_wavelength.svg/1200px-Sine_wavelength.svg.png">Wikipedia</a> shows
      the wavelength
    </p>
    <h3>Time Period</h3>
    <p>
      The time taken by a wave to generate one wavelength is called the time period.
      The time period of a sinusoidal wave is:
      $$T=\frac{2\pi}{\omega}$$
    </p>
    <h3 id="mgk">
      MagicGraph | Wave Travelling through a Medium
    </h3>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <h5> Liked this MagicGraph? Follow this link <a class="icn" href="https://edliy.com/physics/wavet#mgk"> <i class="fa fa-external-link-square" /></a> to explore more MagicGraphs on waves.</h5>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Travelling Wave';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a wave?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Governing equation', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Amplitude', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'Wavelength', img:'/assets/number-4.svg', action: () => this.goto('ka')},
      {title: 'Frequency', img:'/assets/number-5.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Free Body Diagram',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
