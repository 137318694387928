// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch
} from '../../../common/edliy_utils-integral';
const Boxes = {
box1: function () {
          JXG.Options.board.minimizeReflow = 'none';
          JXG.Options.text.highlight =false;
          JXG.Options.text.fixed =true;
          JXG.Options.text.highlight =false;
          JXG.Options.text.cssStyle='fontFamily:Oswald',
          JXG.Options.image.highlight =false;
          JXG.Options.polygon.highlight =false;
          JXG.Options.line.highlight =false;
          JXG.Options.point.highlight =false;
          JXG.Options.curve.highlight =false;
          JXG.Options.slider.highlight =false;
          JXG.Options.point.showInfoBox =false;
          var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 11, 10, -9],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false},  showCopyright:false, showNavigation:false});
          brd1.options.layer['line'] =2;
          brd1.options.layer['polygon'] =2;
          brd1.options.layer['image'] =8;
          makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Amplitude of a Travelling Wave', 'y= A cos(k x - w t)');
    //SLiders
    var amplitud = placeSlider(brd1, -2, 7.5, 1, 2, 5, 4, '&nbsp;A');
    amplitud.setAttribute({snapWidth:1});
    //brd1.create('slider',[[5,7],[8,7],[-5,2,5]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'A', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

    //var frec = placeSlider(brd1, 5, 7, 0, 0.25, 1, 3, '&nbsp;f');
    //frec.setAttribute({visible:false, highline:{visible:false}, baseline:{visible:false}});
    var frec=0.25;
    //brd1.create('slider',[[5,7],[8,7],[0,0.2,1]], {visible: false, snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'f', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    //var phi = brd1.create('slider',[[5,6],[8,6],[-10,0,10]], {visible: false, snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'&phi;', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

    var time1 =placeSlider(brd1, -4, -8, 0, 0, 10, 8, '&nbsp;t');
    time1.setAttribute({unitLabel:'sec'});
    //brd1.create('slider',[[-7,-5],[7,-5],[0,0,10]], {visible: false, snapToGrid: false ,face:'square', size:6, strokeWidth:0,
    //name:'t', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    //Graph
    var graph = brd1.create('functiongraph',
                           [function(x){ return amplitud.Value()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - x));}, -10, 10],
                            {dash: 0, strokeColor: 'blue', strokeWidth: 1}
    );


    //Points
    function yPoints(x){return  amplitud.Value()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - x ))}

    var point1 = placePoint(brd1, -9, function(){return yPoints(-9)}, 3, 'yellow', 'black');
    var point2 = placePoint(brd1, -8, function(){return yPoints(-8)}, 3, 'yellow', 'black');
    var point3 = placePoint(brd1, -7, function(){return yPoints(-7)}, 3, 'yellow', 'black');
    var point4 =placePoint(brd1, -6, function(){return yPoints(-6)}, 3, 'yellow', 'black');
    var point5 =placePoint(brd1, -5, function(){return yPoints(-5)}, 3, 'yellow', 'black');
    var point6 =placePoint(brd1, -4, function(){return yPoints(-4)}, 3, 'yellow', 'black');
    var point7 =placePoint(brd1, -3, function(){return yPoints(-3)}, 3, 'yellow', 'black');
    var point8 =placePoint(brd1, -2, function(){return yPoints(-2)}, 3, 'yellow', 'black');
    var point9 =placePoint(brd1, -1, function(){return yPoints(-1)}, 3, 'yellow', 'black');
    var point10 =placePoint(brd1, -0, function(){return yPoints(-0)}, 3, 'yellow', 'black');
    var point11 =placePoint(brd1, 1, function(){return yPoints(1)}, 3, 'yellow', 'black');
    var point12 =placePoint(brd1, 2, function(){return yPoints(2)}, 3, 'yellow', 'black');
    var point13 =placePoint(brd1, 3, function(){return yPoints(3)}, 3, 'yellow', 'black');
    var point14 =placePoint(brd1, 4, function(){return yPoints(4)}, 3, 'yellow', 'black');
    var point15 =placePoint(brd1, 5, function(){return yPoints(5)}, 3, 'yellow', 'black');
    var point16 =placePoint(brd1, 6, function(){return yPoints(6)}, 3, 'yellow', 'black');
    var point17 =placePoint(brd1, 7, function(){return yPoints(7)}, 3, 'yellow', 'black');
    var point18 =placePoint(brd1, 8, function(){return yPoints(8)}, 3, 'yellow', 'black');
    var point19 =placePoint(brd1, 9, function(){return yPoints(9)}, 3, 'yellow', 'black');
    //brd1.create('point', [-9 ,function(){return yPoints(-9)}],{face:'o' , name:'pp', size:3, strokeColor: 'black', fillColor:'yellow', withLabel:false, fixed:false, visible:true, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    //var point2 = brd1.create('point', [-8 ,function(){return yPoints(-8)}],{face:'o' , name:'pp', size:3, strokeColor: 'black', fillColor:'yellow', withLabel:false, fixed:false, visible:true, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    //var point3 = brd1.create('point', [-7 ,function(){return yPoints(-7)}],{face:'o' , name:'pp', size:3, strokeColor: 'black', fillColor:'yellow', withLabel:false, fixed:false, visible:true, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var segment1 = brd1.create('segment', [[function(){return point1.X()}, 0], point1], {dash: 1, dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment2 = brd1.create('segment', [[function(){return point2.X()}, 0], point2], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment3 = brd1.create('segment', [[function(){return point3.X()}, 0], point3], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment4 = brd1.create('segment', [[function(){return point4.X()}, 0], point4], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment5 = brd1.create('segment', [[function(){return point5.X()}, 0], point5], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment6 = brd1.create('segment', [[function(){return point6.X()}, 0], point6], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment7 = brd1.create('segment', [[function(){return point7.X()}, 0], point7], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment8 = brd1.create('segment', [[function(){return point8.X()}, 0], point8], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment9 = brd1.create('segment', [[function(){return point9.X()}, 0], point9], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment10 = brd1.create('segment', [[function(){return point10.X()}, 0], point10], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment11 = brd1.create('segment', [[function(){return point11.X()}, 0], point11], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment12 = brd1.create('segment', [[function(){return point12.X()}, 0], point12], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment13 = brd1.create('segment', [[function(){return point13.X()}, 0], point13], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment14 = brd1.create('segment', [[function(){return point14.X()}, 0], point14], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment15 = brd1.create('segment', [[function(){return point15.X()}, 0], point15], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment16 = brd1.create('segment', [[function(){return point16.X()}, 0], point16], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment17 = brd1.create('segment', [[function(){return point17.X()}, 0], point17], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment18 = brd1.create('segment', [[function(){return point18.X()}, 0], point18], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});
    var segment19 = brd1.create('segment', [[function(){return point19.X()}, 0], point19], {dash: 1, fixed: true, strokeColor: 'black', strokeWidth:1});

  //  var txt1 = brd1.create('text', [ function(){return point11.X()+ 0.8}, function(){return point11.Y()/2},  function(){return 'A: ' + point11.Y().toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);

    //Lambda

    var lambdaSeg = brd1.create('segment', [[0, function(){return Math.abs(amplitud.Value()) +1}],[function(){return 1/frec.valueOf()}, function(){return Math.abs(amplitud.Value()) +1}]], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                    highlightStrokeWidth: 2,
                                                                                                                    lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                    touchLastPoint: false,
                                                                                                                    firstArrow: {type: 2, size: 8}
    });

    var lambdaTxt = brd1.create('text', [function(){return  1/frec.valueOf()/2 }, function(){return Math.abs(amplitud.Value()) +1.5}, function(){ return '  &lambda; =  ' + (1/frec.valueOf()).toFixed(0) }],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);


    //Animation
    function start(){
        time1.startAnimation(10, 500, 30);
        playImg.setAttribute({visible: false});
        pauseImg.setAttribute({visible: true});

    }

    function pause(){
        time1.stopAnimation(10, 500, 30);
        playImg.setAttribute({visible: true});
        pauseImg.setAttribute({visible: false});

    }
    //Play Button
    var playImg = brd1.create('image', ['/assets/play.svg', [-0.8, -6], [1.5, 1.5]], {visible: true, fixed: true});
    playImg.on('up', start);
    playImg.setLabel('Play')
    playImg.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
    playImg.on('over', function () {this.label.setAttribute({visible:true});});
    playImg.on('out', function () {this.label.setAttribute({visible:false});});
    //Pause Button
    var pauseImg = brd1.create('image', ['/assets/pause.svg', [-0.8, -6], [1.5, 1.5]], {visible: false, fixed: true});
    pauseImg.on('up', pause);
    pauseImg.setLabel('pause')
    pauseImg.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
    pauseImg.on('over', function () {this.label.setAttribute({visible:true});});
    pauseImg.on('out', function () {this.label.setAttribute({visible:false});});
    brd1.unsuspendUpdate();
  }
              }
export default Boxes;
